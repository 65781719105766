import {OfferItemDiscountTypeEnum} from "../../../graphql/$graphql";
import {FilterType, CommonErrorType} from "../../../types/global.types";

export enum OfferDiscountTypes {
    Percent = 'percent',
    Amount = 'amount',
}

export interface ProductSimply {
    readonly id: string;
    readonly name: string;
    readonly code: string;
    readonly priceUnit: number;
    readonly priceUnitRegular: number;
};

export interface OfferItemForm {
    id: string|null,
    product: ProductSimply|null,
    // discountType: OfferDiscountTypes,
    discountType: OfferItemDiscountTypeEnum,
    discountPercent?: number,
    discountAmount?: number,
    quantity?: number,
}

export interface OfferClientFormType {
    id: string,
    name: string,
}

export interface OfferFormType {
    id: string|null,
    title: string,
    number: string,
    status: string|null,
    termDate: string|null,
    client: OfferClientFormType|null,
    description: string,
}

export interface DiscountFormType{
    discount: string
}

export class DiscountFormModel<DiscountFormType> {
    discount: ''
}

export interface DiscountFormErrorType{
    discount?: CommonErrorType[]
}

export class DiscountFormErrorModel<DiscountFormErrorType> {
    discount: []
}

export interface OfferFilterType extends FilterType {
}

import React, {Dispatch, FormEventHandler, ReactElement, SetStateAction, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {Process, ProcessErrorType, ProcessFormType, ProcessItemFormType} from "../../types/process.types";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FormUserSelect} from "../../../user/components";
import {FormClientSelect} from "../../../client/components";
import {FormDepartmentSelect} from "../../../department/components";
import {initProcess} from "../../utils/axios";
import {FormDictionarySelect} from "../../../dictionary/components";
import {CommonErrorType} from "../../../../types/global.types";
import {FormShipmentSelect} from "../../../shipment/components";
import {ShipmentMethodTypeEnum} from "../../../shipment/types/shipmentMethod.types";
import {ShipmentErrorType} from "../../../shipment/types/shipment.types";


interface ProcessFormProps {
    processForm: ProcessFormType,
    onSubmit: Function
    formErrors: ProcessErrorType,
    setFormErrors: Dispatch<SetStateAction<ProcessErrorType>>,
    isSending: boolean
}

const ProcessFormComponent: React.FC<ProcessFormProps> = (
    {processForm: processFormInit, onSubmit, formErrors, setFormErrors, isSending}
): ReactElement => {

    const [processForm, setProcessForm] = useState<ProcessFormType>(processFormInit);

    const removeFromFormErrors = (key: string) => {
        setFormErrors(prevState => {
            let newState = {...prevState};
            delete newState[key];
            return newState;
        })
    }

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setProcessForm(prevState => ({...prevState, [name]: value}));
        removeFromFormErrors(name);
    }

    const onChangeShipmentMethodHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setProcessForm(prevState => {
            const shipment: ShipmentErrorType = {...prevState[name], shipmentMethod: value};
            return {...prevState, [name]: shipment}
        });
        removeFromFormErrors(name);
    }

    const onDepartmentChangeHandler = (departmentId: string) => {
        setProcessForm(prevState => {
            return {...prevState, departmentAccount: departmentId}
        })

        removeFromFormErrors('departmentAccount');
        initProcess({departmentAccount: departmentId}).then((process: Process) => {
            setProcessForm(prevState => {
                return {...prevState, number: process.number ?? ''}
            });
        });
    }

    const onStatusChangeHandler = (statusId: string) => {
        removeFromFormErrors('status');
        setProcessForm(prevState => {
            return {...prevState, status: statusId}
        })
    }

    const onClientChangeHandler = (clientId: string) => {
        removeFromFormErrors('client');
        setProcessForm(prevState => {
            return {...prevState, client: clientId}
        });

        initProcess({client: clientId}).then((process: Process) => {
            setProcessForm(prevState => {
                return {...prevState,
                    customer: {
                        client: clientId,
                        firstName: process?.customer?.firstName ?? '',
                        lastName: process?.customer?.lastName ?? '',
                        companyName: process?.customer?.companyName ?? '',
                        email: process?.customer?.email ?? '',
                        phone: process?.customer?.phone ?? '',
                        taxNumber: process?.customer?.taxNumber ?? '',
                        address: {
                            street: process?.customer?.address?.street ?? '',
                            postCode: process?.customer?.address?.postCode ?? '',
                            city: process?.customer?.address?.city ?? '',
                        }
                    }
                }
            });
        });
    }

    const onUserChangeHandler = (userId: string) => {
        setProcessForm(prevState => {
            return {...prevState, user: userId}
        })
    }

    const onCustomerChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setProcessForm(prevState => (
            {...prevState, customer: {...prevState.customer, [name]: value}}
        ));
        removeFromFormErrors(name);
    }

    const onCustomerAddressChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setProcessForm(prevState => ({
            ...prevState,
            customer: {
                ...prevState.customer,
                address: {...prevState.customer.address, [name]: value}
            }
        }));
        removeFromFormErrors(name);
    }

    const onItemsChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setProcessForm((prevState: ProcessFormType): ProcessFormType => {
            const processItem: ProcessItemFormType = {...prevState.items[0], [name]: value};
            return {...prevState, items: [processItem]};
        });
        removeFromFormErrors(name);
    }

    const onSubmitHandler: FormEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        onSubmit(processForm);
    }


    return (
        <Form onSubmit={onSubmitHandler}>

            <legend>Tytuł</legend>
            <hr className="mb-4"/>
            <Form.Group className="mb-3">
                <Form.Control size="lg" type="text" name="title"
                              value={processForm.title}
                              className={formErrors.title ? ' is-invalid' : ''}
                              onChange={onChangeHandler}
                />
                {formErrors.title && (
                    <Form.Control.Feedback type="invalid">
                        {formErrors.title.map((error: CommonErrorType, key: React.Key) => <div
                            key={key}>{error.message}</div>)}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Row>
                <Col>
                    <legend>Serwis</legend>
                    <hr className="mb-4"/>

                    <Form.Group className="mb-3">
                        <Form.Label className="required">Oddział</Form.Label>
                        <FormDepartmentSelect
                            changeHandler={onDepartmentChangeHandler}
                            value={processForm.departmentAccount}
                            errors={formErrors.departmentAccount}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="required">Numer</Form.Label>
                        <Form.Control type="text" name="number"
                                      className={formErrors.number ? ' is-invalid' : ''}
                                      value={processForm.number ?? ''}
                                      onChange={onChangeHandler}
                        />
                        {formErrors.number && (
                            <Form.Control.Feedback type="invalid">
                                {formErrors.number.map(({message}, key) => <div key={key}>{message}</div>)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="required">Data przyjęcia</Form.Label>
                        <Form.Control type="date" name="date"
                                      className={"form-control " + (formErrors.date ? ' is-invalid' : '')}
                                      value={processForm.date}
                                      onChange={onChangeHandler}
                        />
                        {formErrors.date && (
                            <Form.Control.Feedback type="invalid">
                                {formErrors.date.map(({message}, key) => <div
                                    key={key}>{message}</div>)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="required">Przypisane do</Form.Label>
                        <FormUserSelect
                            changeHandler={onUserChangeHandler}
                            value={processForm.user}
                            errors={formErrors.user}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="required">Status:</Form.Label>
                        <FormDictionarySelect
                            model="ProcessStatus"
                            value={processForm.status}
                            changeHandler={onStatusChangeHandler}
                            errors={formErrors.status}
                        />
                    </Form.Group>

                </Col>
                <Col>
                    <legend>Dane klienta</legend>
                    <hr className="mb-4"/>

                    <Form.Group className="mb-3">
                        <Form.Label>Wyszukaj klienta</Form.Label>

                        <FormClientSelect
                            changeHandler={onClientChangeHandler}
                            value={processForm.customer.client}
                            errors={formErrors.customer?.client ?? []}
                            isClearable={true}
                        />
                    </Form.Group>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label className="required">Imię</Form.Label>
                                <Form.Control type="text" name="firstName"
                                              className={formErrors.customer?.firstName ? ' is-invalid' : ''}
                                              value={processForm.customer.firstName}
                                              onChange={onCustomerChangeHandler}
                                />
                                {formErrors?.customer?.firstName && (
                                    <Form.Control.Feedback type="invalid">
                                        {formErrors.customer.firstName.map((error: CommonErrorType, key: React.Key) =>
                                            <div
                                                key={key}>{error.message}</div>)}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Nazwisko</Form.Label>
                                <Form.Control type="text" name="lastName"
                                              className={formErrors?.customer?.lastName ? ' is-invalid' : ''}
                                              value={processForm.customer.lastName}
                                              onChange={onCustomerChangeHandler}
                                />
                                {formErrors.customer?.lastName && (
                                    <Form.Control.Feedback type="invalid">
                                        {formErrors.customer.lastName.map((error: CommonErrorType, key: React.Key) =>
                                            <div
                                                key={key}>{error.message}</div>)}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control type="text" name="email"
                                              className={formErrors.customer?.email ? ' is-invalid' : ''}
                                              value={processForm.customer.email}
                                              onChange={onCustomerChangeHandler}
                                />
                                {formErrors.customer?.email && (
                                    <Form.Control.Feedback type="invalid">
                                        {formErrors.customer.email.map((error: CommonErrorType, key: React.Key) => <div
                                            key={key}>{error.message}</div>)}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Telefon</Form.Label>
                                <Form.Control type="text" name="phone"
                                              className={formErrors.customer?.phone ? ' is-invalid' : ''}
                                              value={processForm.customer?.phone}
                                              onChange={onCustomerChangeHandler}
                                />
                                {formErrors.customer?.phone && (
                                    <Form.Control.Feedback type="invalid">
                                        {formErrors.customer?.phone.map((error: CommonErrorType, key: React.Key) => <div
                                            key={key}>{error.message}</div>)}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Firma</Form.Label>
                                <Form.Control type="text" name="companyName"
                                              className={formErrors.customer?.companyName ? ' is-invalid' : ''}
                                              value={processForm.customer.companyName}
                                              onChange={onCustomerChangeHandler}
                                />
                                {formErrors.customer?.companyName && (
                                    <Form.Control.Feedback type="invalid">
                                        {formErrors.customer.companyName.map((error: CommonErrorType, key: React.Key) =>
                                            <div
                                                key={key}>{error.message}</div>)}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>NIP</Form.Label>
                                <Form.Control type="text" name="taxNumber"
                                              className={formErrors.customer?.taxNumber ? ' is-invalid' : ''}
                                              value={processForm.customer.taxNumber}
                                              onChange={onCustomerChangeHandler}
                                />
                                {formErrors.customer?.taxNumber && (
                                    <Form.Control.Feedback type="invalid">
                                        {formErrors.customer.taxNumber.map((error: CommonErrorType, key: React.Key) =>
                                            <div
                                                key={key}>{error.message}</div>)}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Ulica</Form.Label>
                                <Form.Control type="text" name="street"
                                              className={formErrors.customer?.address?.street ? ' is-invalid' : ''}
                                              value={processForm.customer.address.street}
                                              onChange={onCustomerAddressChangeHandler}
                                />
                                {formErrors.customer?.address?.street && (
                                    <Form.Control.Feedback type="invalid">
                                        {formErrors.customer.address.street.map((error: CommonErrorType, key: React.Key) =>
                                            <div
                                                key={key}>{error.message}</div>)}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Kod pocztowy</Form.Label>
                                <Form.Control type="text" name="postCode"
                                              className={formErrors.customer?.address?.postCode ? ' is-invalid' : ''}
                                              value={processForm.customer.address.postCode}
                                              onChange={onCustomerAddressChangeHandler}
                                />
                                {formErrors.customer?.address?.postCode && (
                                    <Form.Control.Feedback type="invalid">
                                        {formErrors.customer.address.postCode.map((error: CommonErrorType, key: React.Key) =>
                                            <div
                                                key={key}>{error.message}</div>)}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Miejscowość</Form.Label>
                                <Form.Control type="text" name="city"
                                              className={formErrors.customer?.address?.city ? ' is-invalid' : ''}
                                              value={processForm.customer.address.city}
                                              onChange={onCustomerAddressChangeHandler}
                                />
                                {formErrors.customer?.address?.city && (
                                    <Form.Control.Feedback type="invalid">
                                        {formErrors.customer.address.city.map((error: CommonErrorType, key: React.Key) =>
                                            <div
                                                key={key}>{error.message}</div>)}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                </Col>
            </Row>

            <legend>Szczegóły urządzenia i usterka</legend>
            <hr className="mb-4"/>
            {processForm.items?.map((processItem: ProcessItemFormType, keyItem: string | number) => {
                const itemErrors = formErrors?.items && formErrors?.items[0];
                return (
                    <div key={keyItem}>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Model</Form.Label>
                                    <Form.Control type="text" name="name"
                                                  className={itemErrors?.name ? ' is-invalid' : ''}
                                                  value={processItem.name}
                                                  onChange={onItemsChangeHandler}
                                    />
                                    {itemErrors?.name && (
                                        <Form.Control.Feedback type="invalid">
                                            {itemErrors?.name.map((error: CommonErrorType, key: React.Key) => <div
                                                key={key}>{error.message}</div>)}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Numer seryjny</Form.Label>
                                    <Form.Control type="text" name="serialNumber"
                                                  className={itemErrors?.serialNumber ? ' is-invalid' : ''}
                                                  value={processItem.serialNumber}
                                                  onChange={onItemsChangeHandler}
                                    />
                                    {itemErrors?.serialNumber && (
                                        <Form.Control.Feedback type="invalid">
                                            {itemErrors.serialNumber.map((error: CommonErrorType, key: React.Key) =>
                                                <div
                                                    key={key}>{error.message}</div>)}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Opis usterki</Form.Label>
                                    <Form.Control as="textarea" name="note" rows={3}
                                                  onChange={onItemsChangeHandler}
                                                  value={processItem.note ?? ''}
                                    />
                                    {itemErrors?.note && (
                                        <Form.Control.Feedback type="invalid">
                                            {itemErrors?.note.map((error: CommonErrorType, key: React.Key) => <div
                                                key={key}>{error.message}</div>)}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                )
            })}

            <legend>Opcje zgłoszenia</legend>
            <hr className="mb-4"/>

            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Dostawa do serwisu</Form.Label>
                        <FormShipmentSelect
                            type={ShipmentMethodTypeEnum.incoming}
                            name="incomeShipment"
                            value={processForm.incomeShipment.shipmentMethod}
                            onChange={onChangeShipmentMethodHandler}
                            isInvalid={formErrors?.incomeShipment?.shipmentMethod ? true : false}
                        />
                        {formErrors?.incomeShipment?.shipmentMethod && (
                            <Form.Control.Feedback type="invalid">
                                {formErrors.incomeShipment.shipmentMethod.map((error: CommonErrorType, key) => <div
                                    key={key}>{error.message}</div>)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Zwrot do klienta</Form.Label>
                        <FormShipmentSelect
                            type={ShipmentMethodTypeEnum.outgoing}
                            name="shipment"
                            value={processForm.shipment.shipmentMethod}
                            onChange={onChangeShipmentMethodHandler}
                            isInvalid={formErrors?.shipment?.shipmentMethod ? true : false}
                        />
                        {formErrors?.shipment?.shipmentMethod && (
                            <Form.Control.Feedback type="invalid">
                                {formErrors.shipment.shipmentMethod.map((error: CommonErrorType, key) => <div
                                    key={key}>{error.message}</div>)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Uwagi do zgłoszenia</Form.Label>
                        <Form.Control as="textarea" name="note" rows={3}
                                      onChange={onChangeHandler}
                                      value={processForm.note ?? ''}
                        />
                        {formErrors?.note && (
                            <Form.Control.Feedback type="invalid">
                                {formErrors.note.map((error: CommonErrorType, key) => <div
                                    key={key}>{error.message}</div>)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>

            {/*<hr className="mb-4"/>*/}

            <Button size="lg" type="submit" variant="primary" disabled={isSending}>
                {isSending ? (
                    <><FontAwesomeIcon icon={faSpinnerThird} spin={true}/> wysyłam</>
                ) : 'Zapisz'}
            </Button>
        </Form>
    );
}

export default ProcessFormComponent;
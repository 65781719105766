import React from 'react';
import {ClientDropdownOptions, ClientFormComponent} from "../../components";
import {Link, useParams} from "react-router-dom";
import {useClientQuery} from "../../../../graphql/$graphql";
import {ClientFormType} from "../../types/client.types";
import {getUuidFromId} from "../../../../helpers/utils.helper";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/pro-regular-svg-icons";

interface ClientEditProps{}

const ClientEditView:React.FC<ClientEditProps> = () => {
    const {id} = useParams();
    const {loading, data} = useClientQuery({'variables': {'id': '/api/clients/' + id}});

    const client = data?.client ?? null;
    let initialClientForm: ClientFormType;

    if (client) {
        initialClientForm = {
            name: client.name,
            status: getUuidFromId(client.status.id),
            nip: client.nip,
            phone: client.phone,
            email: client.email,
            street: client.street,
            postCode: client.postCode,
            city: client.city,
        };
    }

    return (
        <>
            <h1>Edycja klienta</h1>

            {loading && <>Loading...</>}

            {client && (
                <>
                    <Row className="mb-3">
                        <Col xs="auto">
                            <Link to={'/clients/' + getUuidFromId(client.id)} className="btn btn-outline-primary">
                                <FontAwesomeIcon icon={faEye} fixedWidth /> Karta klienta
                            </Link>
                        </Col>
                        <Col xs="auto">
                            <ClientDropdownOptions client={client} />
                        </Col>
                    </Row>

                    <ClientFormComponent initialClientForm={initialClientForm} id={getUuidFromId(client.id)}/>
                </>
            )}
        </>
    )
}

export default ClientEditView;

import React, {useEffect} from 'react';
import {Form} from "react-bootstrap";

interface FilterSearchProps {
    value: string;
}

const FilterSearch:React.FC<FilterSearchProps> = ({value}) => {
    const [inputValue, setInputValue] = React.useState<string>(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    return (
        <Form.Control
            value={inputValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
            name="search"
            placeholder="Szukaj"
        />
    )
}

export default FilterSearch;

import {client as clientAxios} from "../../../library/axios.library";
import {AxiosResponse} from "axios";
import {Process, ProcessFormType, ProcessInitRequest} from "../types/process.types";

export const initProcess = async (requestBody?: ProcessInitRequest): Promise<Process> => {
    const {data} = await clientAxios.post<ProcessInitRequest, AxiosResponse<Process>>('/api/v1/process/init', requestBody);
    return data;
}

export const getProcess = async (id: string): Promise<Process> => {
    const {data} = await clientAxios.get<never, AxiosResponse<Process>>('/api/v1/process/' + id);
    return data;
}

export const createProcess = async (data: ProcessFormType) => {
    return await clientAxios.post<ProcessFormType, AxiosResponse>('/api/v1/process', data);
}

export const updateProcess = async (id: string, data: ProcessFormType) => {
    return await clientAxios.patch<never, AxiosResponse>('/api/v1/process/' + id, data);
}
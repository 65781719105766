import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "../../../store";
import {client} from "../../../library/axios.library";

export interface AuthState {
    isInit: boolean;
    isCheckLogin: boolean;
    isAuth: boolean;
    token: string|null
}

const initialState: AuthState = {
    isInit: false,
    isCheckLogin: false,
    isAuth: false,
    token: null
};

export interface loginPayloadAction{
    token: string
}

export interface checkLoginPayloadAction{
    status: number,
    token: string,
}

export const checkLogin = createAsyncThunk(
    'auth/login/check',
    async (token: any) => {
        const response = await client.get('/api/me', {
            headers: {"Authorization": token ? `Bearer ${token}` : ""},
        });

        return {
            status: response.status,
            token: token,
        };
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        initAuth: (state) => {
            const token = window.localStorage.getItem('auth.token');
            // checkLogin('initAutha');
            // state.isInit = true;
            // if (token) {
            //     state.isAuth = true;
            //     state.isCheckLogin = false;
            //     state.token = token;
            // }
        },
        login: (state, action: PayloadAction<loginPayloadAction>) => {
            state.isAuth = true;
            state.isCheckLogin = false;
            state.token = action.payload.token;
            window.localStorage.setItem('auth.token',  action.payload.token);
        },
        logout: (state) => {
            state.isAuth = false;
            state.isCheckLogin = false;
            state.token = null;
            window.localStorage.removeItem('auth.token');
            window.history.pushState({}, "", "/");
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkLogin.pending, (state) => {
                state.isCheckLogin = true;
            })
            .addCase(checkLogin.fulfilled, (state, action: PayloadAction<checkLoginPayloadAction>) => {
                if (200 === action.payload.status) {

                    state.isInit = true;
                    state.isAuth = true;
                    state.isCheckLogin = false;
                    state.token = action.payload.token;
                    window.localStorage.setItem('auth.token',  action.payload.token);
                } else {
                    window.localStorage.removeItem('auth.token');
                }
            })
            .addCase(checkLogin.rejected, (state) => {
                window.localStorage.removeItem('auth.token');
                state.isCheckLogin = false;
            });
    },
})

// export const { removePopupProduct } = authSlice.actions

export const auth = (state: RootState) => state.auth;

export const { initAuth, login, logout } = authSlice.actions

export default authSlice.reducer
import React from "react";
import {useParams} from "react-router-dom";
import {Maybe, UserFragment, useUserQuery} from "../../../../graphql/$graphql";
import {UserForm} from "../../components";
import {UserFormType} from "../../types/user.types";
import {getUuidFromId} from "../../../../helpers/utils.helper";
import {Col, Row} from "react-bootstrap";

interface UserEditProps {}

const UserEditView: React.FC<UserEditProps> = () => {
    const { id } = useParams();
    const {data} = useUserQuery({'variables': {'id': '/api/users/'+id}});
    const user:Maybe<UserFragment> = data?.user ?? null;
    let initialUserForm: UserFormType;

    if (user) {
        initialUserForm = {
            email: user.email ?? '',
            name: user.name ?? '',
            surname: user.surname ?? '',
            phone: user.phone ?? '',
            jobPosition: user.jobPosition ?? '',
            department: user.department ? getUuidFromId(user.department.id) : null,
        };
    }

    return (
        <div className="view-user-edit">
            <h1>Edycja użytkownika</h1>
            {user && (
                <Row>
                    <Col sm md={{span: 8, offset: 2}} >
                        <UserForm initialUserForm={initialUserForm} id={getUuidFromId(user.id)} />
                    </Col>
                </Row>
            )}
        </div>
    );
}

export default UserEditView;

import React, {useEffect, useState} from 'react';
import Select from 'react-select'
import {client as clientAxios} from "../../../../library/axios.library";
import {OfferDiscountTypes, OfferItemForm, ProductSimply} from "../../types/offer.types";
import {toast } from 'react-toastify';
import {Offer, OfferItem, OfferItemDiscountTypeEnum} from "../../../../graphql/$graphql";
import {getUuidFromId} from "../../../../helpers/utils.helper";

interface OfferProp{
    id: string,
    name?: string
}

const defaultOfferItemFormData: OfferItemForm = {
    id: null,
    product: null,
    discountType: 'DISCOUNT_PERCENT',
    discountPercent: null,
    discountAmount: null,
    quantity: 1
};

export interface ItemErrorForm {
    product?: [],
    discountType?: [],
    discountValue?: [],
    quantity?: [],
}

interface OfferItemCreateFormProps {
    offer: OfferProp,
    offerItem?: OfferItem,
    refetch: () => {},
    showOfferItemFormHandler: any,
}

const minSelectInputValue:number = 2;

const initOfferItemFormData = (offerItem:OfferItem|null):OfferItemForm => {
    if (!offerItem) {
        return defaultOfferItemFormData;
    }

    const initProduct = {
        id: offerItem.externalId,
        name: offerItem.name,
        code: offerItem.code,
        priceUnit: parseFloat(offerItem.priceUnitRegular),
        priceUnitRegular: parseFloat(offerItem.priceUnitRegular),
    }

    const offerItemFormData: OfferItemForm = {
        id: offerItem?.id ?? null,
        product: initProduct,
        discountType: offerItem.discountType,
        discountPercent: offerItem?.percentDiscount ? parseFloat(offerItem.percentDiscount) : null,
        discountAmount: offerItem?.priceUnitDiscount ? parseFloat(offerItem.priceUnitDiscount) : null,
        quantity: offerItem?.quantity ?? 1,
    };

    return offerItemFormData;
}

const OfferItemFormComponent:React.FC<OfferItemCreateFormProps> =
    ({offer, offerItem, refetch, showOfferItemFormHandler}) => {

    const [productList, setProductList] = useState<ProductSimply[]>([]);
    const [showProductOptions, setShowProductOptions] = useState(minSelectInputValue === 0);
    const [formErrors, setFormErrors] = useState<ItemErrorForm>({});
    const [formData, setFormData] = useState<OfferItemForm>(() => initOfferItemFormData(offerItem));
    const [isSending, setIsSending] = useState<boolean>(false)
    const requestMethod = offerItem?.id ? 'patch' : 'post';
    const requestUrl = offerItem?.id
        ? '/api/v1/offers/'+getUuidFromId(offer.id)+'/items/external/'+getUuidFromId(offerItem.id)
        : '/api/v1/offers/'+getUuidFromId(offer.id)+'/items/external';

    const loadProducts = () => {
        clientAxios.get('/api/v1/products/external/simply')
            .then(response => {
                setProductList(response.data.data);
            });
    }

    const setProductHandler = product => {
        setFormData( prevState => {
            return {...prevState, product, discountAmount: 0}
        })
    }

    const onChangeHandler = e => {
        const name = e.target.name;
        const type = e.target.type;
        let value = null;

        if(type === 'checkbox'){
            value = e.target.checked;
        }else{
            value = e.target.value;
        }

        setFormData(prevState => {
            return {...prevState, ...{[name]: value}}
        });
    }

    const onSubmitHandler = (e) => {
        e.preventDefault();
        setIsSending(true);

        const requestData = {
            product: formData?.product?.id ?? null,
            discountType: formData.discountType === 'DISCOUNT_PERCENT'
                ? OfferDiscountTypes.Percent : OfferDiscountTypes.Amount,
            discountValue: formData.discountType === 'DISCOUNT_PERCENT'
                ? formData.discountPercent : formData.discountAmount,
            quantity: formData.quantity,
        };

        clientAxios.request({
            method: requestMethod,
            url: requestUrl,
            data: requestData
        })
            .then(response => {
                refetch();
                toast.success("Zapisano");
                setFormData({...formData, product: null});
                showOfferItemFormHandler(false)
            })
            .catch(error => {
                if (400 === error.response.status) {
                    let errors = [];
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            }).finally(() => {
            setIsSending(false);
        })
    }

    useEffect(() => {
        loadProducts();
    }, []);

    const getDiscountedAmount = () => {
        let discountAmount = 0;

        if (formData.product) {
            if ('DISCOUNT_PERCENT' === formData.discountType) {
                discountAmount = ((formData.discountPercent/100) * formData.product.priceUnit);
                discountAmount = Math.round(discountAmount * 100) / 100;
            } else if ('DISCOUNT_AMOUNT' === formData.discountType) {
                discountAmount = formData.discountAmount;
            }
        }

        let discountedAmount = formData.product.priceUnit - discountAmount;
        if (discountedAmount < 0) { discountedAmount = 0; }

        return discountedAmount;
    }

    return (
        <form action="" onSubmit={onSubmitHandler}>
            <div className="mb-3 row">
                <label htmlFor="staticEmail" className="col-sm-4 col-form-label text-end required">Produkt:</label>
                <div className="col-sm-8">
                    <Select
                        autoFocus={true}
                        value={formData.product}
                        placeholder={'wybierz produkt...'}
                        getOptionLabel={(product) => product.code + ' ' + product.name}
                        getOptionValue={(product) => product.id}
                        onChange={setProductHandler}
                        className={"p-0 form-select " + (formErrors.product ? ' is-invalid' : '')}
                        options={showProductOptions ? productList : []}
                        onInputChange={(typedOption) => setShowProductOptions(typedOption.length >= minSelectInputValue)}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                border: '0',
                            })
                        }}
                        noOptionsMessage={() => (
                            showProductOptions
                                ? 'Nie znalazłem produktu.'
                                : 'Podaj przynajmniej ' + minSelectInputValue + ' znaki, aby wyszukać produkt.'
                        )}
                    />
                    {formErrors.product && (
                        <div className="invalid-feedback">
                            {formErrors.product.map(({message}, key) => <div key={key}>{message}</div>)}
                        </div>
                    )}
                </div>
            </div>

            <div className="mb-3 row ">
                <label className="col-sm-4 col-form-label text-end">Cena podstawowa:</label>
                <div className="col-sm-8">
                    <div className="form-control-plaintext">
                        {formData.product && (
                            <>{formData.product.priceUnit.toFixed(2)} <span className="small text-muted">zł</span></>
                        )}
                    </div>
                </div>
            </div>

            <div className="mb-3 row">
                <label className="col-sm-4 col-form-label- text-end required">Typ rabatu:</label>
                <div className="col-sm-8">
                    <div className={formErrors.discountType ? ' is-invalid' : ''}>
                        <div className="form-check form-check-inline">
                            <input className={"form-check-input " + (formErrors.discountType ? ' is-invalid' : '')}
                                   type="radio" name="discountType"
                                   id="discountType-percent"
                                   value={'DISCOUNT_PERCENT'}
                                   checked={'DISCOUNT_PERCENT' === formData.discountType}
                                   onChange={onChangeHandler}
                            />
                            <label className="form-check-label"
                                   htmlFor="discountType-percent"
                            >
                                Procentowy
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className={"form-check-input " + (formErrors.discountType ? ' is-invalid' : '')}
                                   type="radio" name="discountType"
                                   id="discountType-amount"
                                   value={'DISCOUNT_AMOUNT'}
                                   checked={'DISCOUNT_AMOUNT' === formData.discountType}
                                   onChange={onChangeHandler}
                            />
                            <label className="form-check-label"
                                   htmlFor="discountType-amount"
                            >
                                Kwotowy
                            </label>
                        </div>
                    </div>
                    {formErrors.discountType && (
                        <div className="invalid-feedback">
                            {formErrors.discountType.map(({message}, key) => <div key={key}>{message}</div>)}
                        </div>
                    )}
                </div>
            </div>

            {formData.discountType === 'DISCOUNT_PERCENT' && (
                <div className="mb-3 row">
                    <label className="col-sm-4 col-form-label text-end required">Wartość %:</label>
                    <div className="col-sm-8">
                        <div className="input-group" style={{width: '300px'}}>
                            <input type="text" name="discountPercent"
                                   className={"form-control " + (formErrors.discountValue ? ' is-invalid' : '')}
                                   value={formData?.discountPercent || ''}
                                   onChange={onChangeHandler}
                            />
                            <span className="input-group-text" id="addon-wrapping">%</span>
                            {formErrors.discountValue && (
                                <div className="invalid-feedback">
                                    {formErrors.discountValue.map(({message}, key) => <div key={key}>{message}</div>)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {formData.discountType === "DISCOUNT_AMOUNT" && (
                <div className="mb-3 row">
                    <label className="col-sm-4 col-form-label required">Wartość PLN:</label>
                    <div className="col-sm-8">
                        <div className="input-group" style={{width: '300px'}}>
                            <input type="text" name="discountAmount"
                                   className={"form-control " + (formErrors.discountValue ? ' is-invalid' : '')}
                                   value={formData?.discountAmount || ''}
                                   onChange={onChangeHandler}
                            />
                            <span className="input-group-text" id="addon-wrapping">PLN</span>
                            {formErrors.discountValue && (
                                <div className="invalid-feedback">
                                    {formErrors.discountValue.map(({message}, key) => <div key={key}>{message}</div>)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className="mb-3 row">
                <label className="col-sm-4 col-form-label text-end">Cena jednostkowa:</label>
                <div className="col-sm-8">
                    <div className="form-control-plaintext">
                        {formData.product && (
                            <>{getDiscountedAmount().toFixed(2)} <span className="small text-muted">zł</span></>
                        )}
                    </div>
                </div>
            </div>

            <div className="mb-3 row">
                <label className="col-sm-4 col-form-label text-end required">Ilość:</label>
                <div className="col-sm-8">
                    <div className="input-group" style={{width: '300px'}}>
                        <input type="text" name="quantity"
                               className={"form-control " + (formErrors.quantity ? ' is-invalid' : '')}
                               value={formData.quantity}
                               onChange={onChangeHandler}
                        />
                        <span className="input-group-text" id="addon-wrapping">szt.</span>
                        {formErrors.quantity && (
                            <div className="invalid-feedback">
                                {formErrors.quantity.map(({message}, key) => <div key={key}>{message}</div>)}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="mb-3 row">
                <label htmlFor="staticEmail" className="col-sm-4 col-form-label"></label>
                <div className="col-sm-8">
                    <button type="submit" className="btn btn-outline-secondary">Zapisz produkt</button>
                </div>
            </div>
        </form>
    );
    }

export default OfferItemFormComponent;
import React, {useEffect, useState} from "react";
import {
    useDepartmentsLazyQuery,
} from "../../../../graphql/$graphql";
import {getUuidFromId} from "../../../../helpers/utils.helper";
import {Form} from "react-bootstrap";
import {CommonErrorType} from "../../../../types/global.types";

interface FormDepartmentSelectProps {
    changeHandler: Function,
    value: string,
    errors?: CommonErrorType[]
    name?: string,
}

const FormDepartmentSelectComponent: React.FC<FormDepartmentSelectProps> = (
    {changeHandler, value, errors = [], name = "department"}
) => {
    const [selectedDepartment, setSelectedDepartment] = useState<string>(value);
    const [departmentsQuery, {refetch}] = useDepartmentsLazyQuery();
    const [departmentList, setDepartmentList] = useState<any[]>([]);

    useEffect(() => {
        refetch().then(result => {
            setDepartmentList(result?.data?.departments ?? []);
        })
    }, [])

    const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const departmentId = e.target.value;
        setSelectedDepartment(departmentId);
        changeHandler(departmentId)
    }

    return (
        <>
            <Form.Select
                value={selectedDepartment}
                name={name}
                onChange={onChangeHandler}
                isInvalid={errors.length > 0 ? true : false}
            >
                <option value="">- wybierz oddział -</option>
                {departmentList.map(department => (
                    <option value={getUuidFromId(department.id)} key={department.id}>
                        {department.code ? department.code + ' - ' : ''}{department.name}
                    </option>
                ))}
            </Form.Select>

            {errors.length > 0 && (
                <Form.Control.Feedback type="invalid">
                    {errors.map((error, key) => <div
                        key={key}>{error.message}</div>)}
                </Form.Control.Feedback>
            )}
        </>
    );
}

export default FormDepartmentSelectComponent;

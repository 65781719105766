import React, {ReactElement} from "react";
import FilterComponent from "../../../../components/Filter/Filter.component";
import FilterSearch from "../../../../components/Filter/components/FilterSearch/FilterSearch.component";
import {OfferFilterType} from "../../types/offer.types";

interface OfferFilterProps {
    clientFilter: OfferFilterType
}

const OfferFilterComponent:React.FC<OfferFilterProps> = ({clientFilter}): ReactElement => {
    let defaultFilter: OfferFilterType = {};
    if (typeof clientFilter.order !== "undefined") {
        defaultFilter.order = clientFilter.order;
    }

    return (
        <FilterComponent defaultParams={defaultFilter}>
            <FilterSearch value={clientFilter.search ?? ''} />
        </FilterComponent>
    );
}

export default OfferFilterComponent;
import React, {ReactElement} from "react";
import FilterComponent from "../../../../components/Filter/Filter.component";
import FilterSearch from "../../../../components/Filter/components/FilterSearch/FilterSearch.component";
import {ProcessFilterType} from "../../types/process.types";

interface ProcessFilterProps {
    processFilter: ProcessFilterType
}

const ProcessFilterComponent:React.FC<ProcessFilterProps> = ({processFilter}): ReactElement => {
    let defaultFilter: ProcessFilterType = {};
    if (typeof processFilter.order !== "undefined") {
        defaultFilter.order = processFilter.order;
    }

    return (
        <FilterComponent defaultParams={defaultFilter}>
            <FilterSearch value={processFilter.search ?? ''} />
        </FilterComponent>
    );
}

export default ProcessFilterComponent;
import {client as clientAxios} from "../../../../library/axios.library";
import {AxiosResponse} from "axios";
import {UserFormType} from "../../types/user.types";


export const saveUser = async (data: UserFormType, id?: string) => {
    if (id) {
        return updateUser(id, data);
    }

    return createUser(data);
}

export const createUser = async (data: UserFormType) => {
    return await clientAxios.post<never, AxiosResponse>('/api/v1/users', data);
}

export const updateUser = async (id: string, data: UserFormType) => {
    return await clientAxios.patch<never, AxiosResponse>('/api/v1/users/' + id, data);
}

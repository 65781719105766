import React, {ReactElement, ReactNode} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {objectToUrl} from "../../helpers/utils.helper";
import {ClientFilterType} from "../../modules/client/types/client.types";

interface FilterProps{
    children: ReactNode,
    defaultParams?: ClientFilterType
}

const FilterComponent:React.FC<FilterProps> = ({children, defaultParams}): ReactElement => {
    const navigate = useNavigate();

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget)

        let filterParams = {...defaultParams};
        formData.forEach((value, key) => {
            if (value) { filterParams[key] = value }
        });

        let filterUrl = '';
        if (Object.values(filterParams).length > 0) {
            filterUrl += '?' + objectToUrl(filterParams).split('&').filter(Boolean).join('&');
        }

        navigate(filterUrl);
    }

    return (
        <Form className="mb-3" onSubmit={onSubmitHandler} method="get">
            <Row>
                <Col xs="auto">
                    {children}
                </Col>

                <Col xs="auto">
                    <Button type="submit">
                        Szukaj
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}


export default FilterComponent;
import React, {useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import Table from 'react-bootstrap/Table';
import {
    UserFragment,
    UserPaginationInfoFragment,
    useUsersLazyQuery
} from "../../../../graphql/$graphql";
import SortableColumnComponent from "../../../../components/SortableColumn/SortableColumn.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsis, faGear} from "@fortawesome/pro-regular-svg-icons";
import {faUserPlus} from "@fortawesome/pro-light-svg-icons";
import {Pagination} from "../../../../components/Pagination";
import {getUuidFromId} from "../../../../helpers/utils.helper";
import {Button, Col, Row} from "react-bootstrap";

interface UserListViewProps {}

const parseSearchParams = (search:string):any => {
    const locationParams = new URLSearchParams(search);
    let result = {
        page: Number(locationParams.get('page') ?? 1),
        order: [],
    };

    locationParams.forEach((value, key) => {
        if (key.startsWith('order[')) {
            const innerKey = key.slice(6, -1); // Extracting the inner key
            result.order.push({[innerKey]: value});
        }
    });
    return result;
}


const UserListView:React.FC<UserListViewProps> = () => {
    const locationParams = parseSearchParams(useLocation().search);
    const [usersQuery, {loading, data, error}] = useUsersLazyQuery();
    const users = data?.users?.collection ?? [];
    const paginationInfo: UserPaginationInfoFragment = data?.users?.paginationInfo;

    useEffect(() => {
        usersQuery({'variables': locationParams});
    }, [useLocation().pathname, useLocation().search])

    return (
        <div className="view-user-list">
            <div className="d-flex align-content-center align-items-center justify-content-between mb-4">
                <div>
                    <h1 className="m-0">Lista użytkowników</h1>
                </div>
                <div>
                    <Link to="/user/create" className="btn btn-outline-primary">
                        <FontAwesomeIcon icon={faUserPlus} fixedWidth={true} /> dodaj użytkownika
                    </Link>
                </div>
            </div>

            <Table bordered hover size="sm" >
                <thead>
                <tr>
                    <th scope="col">
                        <SortableColumnComponent label="Imię" orderKey="name" params={locationParams}/>
                    </th>
                    <th scope="col">
                        <SortableColumnComponent label="Nazwisko" orderKey="surname" params={locationParams}/>
                    </th>
                    <th scope="col">
                        <SortableColumnComponent label="E-mail" orderKey="email" params={locationParams}/>
                    </th>
                    <th scope="col">Telefon</th>
                    <th scope="col">Oddział</th>
                    <td className="text-center">
                        <a href="#"><FontAwesomeIcon icon={faGear}/></a>
                    </td>
                </tr>
                </thead>
                <tbody>
                    {users.map((user: UserFragment) => (
                        <tr key={user.id}>
                            <td>
                                <Link to={'/user/'+getUuidFromId(user.id)}>{user.name}</Link>
                            </td>
                            <td>
                                <Link to={'/user/'+getUuidFromId(user.id)}>{user.surname}</Link>
                            </td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td>
                            <td>{user.department?.name}</td>
                            <td className="text-center">
                                <a href="#"><FontAwesomeIcon icon={faEllipsis}/></a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {paginationInfo && <Pagination page={locationParams.page} {...paginationInfo} />}
        </div>
    );
}

export default UserListView;
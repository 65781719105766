import React from 'react';

interface NumberFormatProps{
    value: number|string,
    extension?: string
}

const NumberFormat:React.FC<NumberFormatProps> = ({value, extension}) => {
    const ext = extension ?? 'zł';
    const v = typeof value === 'string' ? parseFloat(value) : value;
    const priceInt =  Math.floor(v);
    const priceFloat = (v - priceInt).toFixed(2).toString().slice(2,4);
    const priceIntFormat = priceInt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return (
        <span className="text-nowrap">
            {priceIntFormat}
            <small className="text-muted">,{priceFloat}</small>
            &nbsp;<small className="text-muted">{ext}</small>
        </span>
    )
}

export default NumberFormat;
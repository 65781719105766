import React from 'react';

import {ClientFormComponent} from "../../components";
import {ClientFormType} from "../../types/client.types";

const initialClientForm: ClientFormType = {
    name: '',
    status: '',
    nip: '',
    phone: '',
    email: '',
    street: '',
    postCode: '',
    city: '',
};

const ClientCreateView = () => {
    return (
        <>
            <h1>Dodanie nowego klienta</h1>
            <ClientFormComponent initialClientForm={initialClientForm}/>
        </>
    );

}

export default ClientCreateView;
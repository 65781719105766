import React from 'react';
import {Link, useParams} from "react-router-dom";
import {useClientQuery} from "../../../../graphql/$graphql";
import {ClientCard, ClientDropdownOptions} from "../../components";
import {getUuidFromId} from "../../../../helpers/utils.helper";
import {faPlus} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Row} from "react-bootstrap";

const ClientView = () => {
    const { id } = useParams();
    const {loading, data, error} = useClientQuery({
        'variables': {'id': '/api/clients/'+id}
    });

    const client = data?.client ?? null;

    return (
        <>
            <h1>Klient</h1>

            {loading && (
                <div>Loading...</div>
            )}

            { client && (
                <>
                    <Row className="mb-3">
                        <Col xs="auto">
                            <Link to={`/offers/create?client=`+ getUuidFromId(client.id)} className="btn btn-outline-primary">
                                <FontAwesomeIcon icon={faPlus} fixedWidth /> Dodaj ofertę
                            </Link>
                        </Col>
                        <Col xs="auto">
                            <ClientDropdownOptions client={client} />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <ClientCard client={client}/>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );

}

export default ClientView;
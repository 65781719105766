import React from 'react';
import {
    useOfferQuery
} from "../../../../graphql/$graphql";
import {OfferForm} from "../../components";
import {OfferFormType} from "../../types/offer.types";
import {useParams} from "react-router-dom";
import {getUuidFromId} from "../../../../helpers/utils.helper";
import moment from "moment";

const initOfferFormData = (offer:any):OfferFormType => {

    let client = null;
    if (offer.client) {
        client = {
            id: getUuidFromId(offer.client.id),
            name: offer.client.name,
        }
    }

    const offerFormData: OfferFormType = {
        id: offer?.id ?? null,
        title: offer.title,
        number: offer.number,
        status: getUuidFromId(offer.status.id),
        termDate: offer.termDate ? moment(offer.termDate).format('YYYY-MM-DD') : null,
        client: client,
        description: offer.description,
    };

    return offerFormData;
}

const OfferEditView = () => {
    const { id } = useParams();
    const {loading, data} = useOfferQuery({'variables': {'id': '/api/offers/'+id}});

    const offer = data?.offer ?? null;

    return (
        <>
            <h1 className="text-center">Edycja oferty</h1>
            <div className="p-3">
                <h5 className="mb-3">Dane oferty</h5>
                <hr/>
                {loading && <>Loading...</>}
                {offer && <OfferForm offerForm={initOfferFormData(offer)} />}
            </div>
        </>
    );
}

export default OfferEditView;
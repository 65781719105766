import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom"
import {OfferFormType} from "../../types/offer.types";
import {getUuidFromId} from "../../../../helpers/utils.helper";
import {client as clientAxios} from "../../../../library/axios.library";
import {toast} from "react-toastify";
import {useClientsLazyQuery, useDictionariesQuery} from "../../../../graphql/$graphql";
import Select from "react-select";

interface OfferFormProps {
    offerForm: OfferFormType,// Offer,
}

let timer;

const OfferFormComponent:React.FC<OfferFormProps> = ({offerForm}) => {
    const navigate = useNavigate();
    const [isSending, setIsSending] = useState<boolean>(false)
    const [clientsQuery, { refetch: clientsQueryRefresh }] = useClientsLazyQuery();
    const {data: dictionariesData} = useDictionariesQuery({
        'variables': {'model': 'OfferStatus'},
    });
    const [formErrors, setFormErrors] = useState<any>({});
    const [offerFormData, setOfferFormData] = useState<OfferFormType>(offerForm);
    const [clientList, setClientList] = useState<any[]>([]);
    const [clientListLoading, setClientListLoading] = useState<boolean>(false);

    const requestMethod = offerForm?.id ? 'patch' : 'post';
    const requestUrl = offerForm?.id ? '/api/v1/offers/'+getUuidFromId(offerForm.id) : '/api/v1/offers';

    const onChangeHandler = e => {
        const name = e.target.name;
        const type = e.target.type;
        let value = null;

        if(type === 'file') {
            value = e.target.files[0];
        }else if(type === 'checkbox'){
            value = e.target.checked;
        }else{
            value = e.target.value;
        }

        setOfferFormData(prevState => {
            return {...prevState, ...{[name]: value}}
        });
        setFormErrors(prevState => {
            let newState = {...prevState};
            delete newState[name];
            return newState;
        })
    }

    const onChangeClient = client => {
        setOfferFormData( prevState => {
            return {...prevState, client: client}
        })

        setFormErrors(prevState => {
            let newState = {...prevState};
            delete newState.client;
            return newState;
        })
    }

    const onSubmitHandler = async e =>  {
        e.preventDefault();
        setIsSending(true);

        let requestData: any = {...offerFormData};
        requestData.client = offerFormData.client.id
        delete requestData.id;

        clientAxios.request({
            method: requestMethod,
            url: requestUrl,
            data: requestData
        })
            .then(response => {
                toast.success("Oferta została zapisana");
                navigate("/offers/"+response.data.id);
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            }).finally(() => {
            setIsSending(false);
        })
    }

    const fetchClients = (inputValue:string = '') => {
        setClientListLoading(true);
        clientsQueryRefresh({'search': inputValue})
            .then(clientsQueryResult => {
                const clients = clientsQueryResult?.data?.clients?.collection ?? [];
                let selectOptions = [];
                clients.map(client => {
                    selectOptions.push({id: getUuidFromId(client.id), name: client.name});
                })
                setClientList(selectOptions);
                setClientListLoading(false);
            });
        }

    const onInputChangeHandler = (inputValue:string) => {
        if (timer) { clearTimeout(timer); }
        timer = setTimeout(() => {
            fetchClients(inputValue)
        }, 250);
    };

    useEffect(() => {
        setOfferFormData(offerForm);
        if (offerForm.client) {
            setClientList([offerForm.client])
        } else {
            fetchClients();
        }
    }, [offerForm]);


    const dictionaries = dictionariesData?.dictionaries ?? [];

    return (
        <>
            <form onSubmit={onSubmitHandler}>
                <div className="mb-3">
                    <label className="form-label required">Tytuł oferty</label>
                    <input type="text"
                           className={"form-control form-control-lg " + (formErrors.title ? ' is-invalid' : '')}
                           name="title"
                           value={offerFormData.title}
                           onChange={onChangeHandler}
                    />
                    {formErrors.title && (
                        <div className="invalid-feedback">
                            {formErrors.title.map(({message}, key) => <div key={key}>{message}</div>)}
                        </div>
                    )}
                </div>

                <div className="row">
                    <div className="col">
                        <div className="mb-3">
                            <label className="form-label required">Numer oferty</label>
                            <input type="text" name="number"
                                   className={"form-control " + (formErrors.number ? ' is-invalid' : '')}
                                   value={offerFormData.number}
                                   onChange={onChangeHandler}
                            />
                            {formErrors.number && (
                                <div className="invalid-feedback">
                                    {formErrors.number.map(({message}, key) => <div key={key}>{message}</div>)}
                                </div>
                            )}
                        </div>

                        <div className="mb-3">
                            <label className="form-label required">Status:</label>
                            <select className={"form-select " + (formErrors.status ? ' is-invalid' : '')}
                                    name="status"
                                    value={offerFormData.status}
                                    onChange={onChangeHandler}
                            >
                                {dictionaries.map(dictionary => (
                                    <option value={getUuidFromId(dictionary.id)} key={dictionary.id}>
                                        {dictionary.name}
                                    </option>
                                ))}
                            </select>
                            {formErrors.status && (
                                <div className="invalid-feedback">
                                    {formErrors.status.map(({message}, key) => <div key={key}>{message}</div>)}
                                </div>
                            )}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Data ważności oferty</label>
                            <input type="date" name="termDate"
                                   className={"form-control " + (formErrors.termDate ? ' is-invalid' : '')}
                                   value={offerFormData.termDate}
                                   onChange={onChangeHandler}
                            />
                            {formErrors.termDate && (
                                <div className="invalid-feedback">
                                    {formErrors.termDate.map(({message}, key) => <div
                                        key={key}>{message}</div>)}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col">
                        <div className="mb-3">
                            <label className="form-label required">Klient</label>
                            <Select
                                 options={clientList}
                                 name="client"
                                 value={offerFormData.client}
                                 getOptionLabel={(client: any) => client.name}
                                 getOptionValue={(client: any) => client.id}
                                 className={"p-0 form-select " + (formErrors.client ? ' is-invalid' : '')}
                                 onChange={onChangeClient}
                                 onInputChange={onInputChangeHandler}
                                 placeholder="Wybierz klienta"
                                 isLoading={clientListLoading}
                                 loadingMessage={() => "Szukam..."}
                                 noOptionsMessage={() => "Nie znaleziono klienta"}
                                 styles={{
                                     control: (baseStyles, state) => ({...baseStyles, border: '0'})
                                 }}
                            />
                            {formErrors.client && (
                                <div className="invalid-feedback">
                                    {formErrors.client.map(({message}, key) => <div key={key}>{message}</div>)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <hr className="mb-4"/>
                <h5 className="mb-3">Opis oferty</h5>
                <textarea
                    className={"form-control " + (formErrors.description ? ' is-invalid' : '')}
                    name="description"
                    value={offerFormData.description}
                    onChange={onChangeHandler}
                    rows={3}
                />

                <hr className="mb-4"/>

                <div className="text-center">
                    <button
                        type="submit"
                        className={"btn btn-secondary btn " + (isSending ? 'disabled' : '')}
                    >
                        {isSending ? 'Wysyłam...' : 'Zapisz'}
                    </button>
                </div>
            </form>
        </>
    )
}

export default OfferFormComponent;



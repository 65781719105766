import React, {ReactElement, useState} from 'react';
import {useDictionariesQuery} from "../../../../graphql/$graphql";
import {ClientFormErrorType, ClientFormType} from "../../types/client.types";
import {client as clientAxios} from "../../../../library/axios.library";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird, faEye} from "@fortawesome/pro-regular-svg-icons";
import {getUuidFromId} from "../../../../helpers/utils.helper";

interface ClientFormProps{
    initialClientForm: ClientFormType,
    id?: string
}

const ClientFormComponent:React.FC<ClientFormProps> = ({initialClientForm, id}): ReactElement => {
    const navigate = useNavigate();
    const {data: dictionariesData} = useDictionariesQuery({
        'variables': {'model': 'ClientStatus'},
        'onCompleted': (data) => onDictionariesCompleted(data)
    });
    const [formErrors, setFormErrors] = useState<ClientFormErrorType>({});
    const [clientFormData, setClientFormData] = useState<ClientFormType>(initialClientForm);
    const [isSending, setIsSending] = useState<boolean>(false)

    const requestMethod = id ? 'patch' : 'post';
    const requestUrl = id ? '/api/v1/clients/'+id : '/api/v1/clients';

    const onDictionariesCompleted = (data) => {
        if (0 === clientFormData?.status?.length) {
            let defaultStatus = data.dictionaries.find(dictionary => dictionary.default);
            if (undefined === defaultStatus) {
                defaultStatus = data.dictionaries[0];
            }

            if (defaultStatus) {
                setClientFormData(prevState => {
                    return {...prevState, status: getUuidFromId(defaultStatus.id)}
                })
            }
        }
    }

    const onChangeHandler = e => {
        const name = e.target.name;
        const type = e.target.type;
        let value = null;

        if(type === 'file') {
            value = e.target.files[0];
        }else if(type === 'checkbox'){
            value = e.target.checked;
        }else{
            value = e.target.value;
        }

        setClientFormData(prevState => {
            return {...prevState, ...{[name]: value}}
        });
    }

    const onSubmitHandler = async e =>  {
        e.preventDefault();
        setIsSending(true);

        clientAxios.request({
            method: requestMethod,
            url: requestUrl,
            data: clientFormData
        })
            .then(response => {
                toast.success("Klient został zapisany");
                navigate("/clients/"+response.data.id);
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            }).finally(() => {
                setIsSending(false);
            })
    }

    const dictionaries = dictionariesData?.dictionaries ?? [];

    return (
        <form onSubmit={onSubmitHandler}>

            <div className="mb-3">
                <label htmlFor="client-name" className="form-label">Nazwa klienta:</label>
                <input type="text"
                       className={"form-control " + (formErrors.name ? ' is-invalid' : '')}
                       name="name"
                       value={clientFormData.name}
                       onChange={onChangeHandler}
                />
                {formErrors.name && (
                    <div className="invalid-feedback">
                        {formErrors.name.map((error, key) => <div key={key}>{error.message}</div>)}
                    </div>
                )}
            </div>

            <div className="row">
                <div className="col">
                    <div className="mb-3">
                        <label className="form-label">Status:</label>
                        <select className={"form-select " + (formErrors.status ? ' is-invalid' : '')}
                                name="status"
                                value={clientFormData.status}
                                onChange={onChangeHandler}
                        >
                            {dictionaries.map(dictionary => (
                                <option value={getUuidFromId(dictionary.id)} key={dictionary.id}>
                                    {dictionary.name}
                                </option>
                            ))}
                        </select>
                        {formErrors.status && (
                            <div className="invalid-feedback">
                                {formErrors.status.map((error, key) => <div key={key}>{error.message}</div>)}
                            </div>
                        )}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">NIP:</label>
                        <input type="text"
                               className={"form-control " + (formErrors.nip ? ' is-invalid' : '')}
                               name="nip"
                               value={clientFormData.nip}
                               onChange={onChangeHandler}
                        />
                        {formErrors.nip && (
                            <div className="invalid-feedback">
                                {formErrors.nip.map((error, key) => <div key={key}>{error.message}</div>)}
                            </div>
                        )}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Telefon:</label>
                        <input type="text"
                               className={"form-control " + (formErrors.phone ? ' is-invalid' : '')}
                               name="phone"
                               value={clientFormData.phone}
                               onChange={onChangeHandler}
                        />
                        {formErrors.phone && (
                            <div className="invalid-feedback">
                                {formErrors.phone.map((error, key) => <div key={key}>{error.message}</div>)}
                            </div>
                        )}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">E-mail:</label>
                        <input type="email"
                               className={"form-control " + (formErrors.email ? ' is-invalid' : '')}
                               name="email"
                               value={clientFormData.email}
                               onChange={onChangeHandler}
                        />
                        {formErrors.email && (
                            <div className="invalid-feedback">
                                {formErrors.email.map((error, key) => <div key={key}>{error.message}</div>)}
                            </div>
                        )}
                    </div>

                </div>
                <div className="col">

                    <div className="mb-3">
                        <label className="form-label">Ulica:</label>
                        <input type="text"
                               className={"form-control " + (formErrors.street ? ' is-invalid' : '')}
                               name="street"
                               value={clientFormData.street}
                               onChange={onChangeHandler}
                        />
                        {formErrors.street && (
                            <div className="invalid-feedback">
                                {formErrors.street.map((error, key) => <div key={key}>{error.message}</div>)}
                            </div>
                        )}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Kod pocztowy:</label>
                        <input type="text"
                               className={"form-control " + (formErrors.postCode ? ' is-invalid' : '')}
                               name="postCode"
                               value={clientFormData.postCode}
                               onChange={onChangeHandler}
                        />
                        {formErrors.postCode && (
                            <div className="invalid-feedback">
                                {formErrors.postCode.map((error, key) => <div key={key}>{error.message}</div>)}
                            </div>
                        )}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Miejscowość:</label>
                        <input type="text"
                               className={"form-control " + (formErrors.city ? ' is-invalid' : '')}
                               name="city"
                               value={clientFormData.city}
                               onChange={onChangeHandler}
                        />
                        {formErrors.city && (
                            <div className="invalid-feedback">
                                {formErrors.city.map((error, key) => <div key={key}>{error.message}</div>)}
                            </div>
                        )}
                    </div>

                </div>
            </div>
            <button type="submit" className="btn btn-primary" disabled={isSending}>
                {isSending ? (
                    <><FontAwesomeIcon icon={faSpinnerThird} spin={true} /> wysyłam</>
                ) : 'Zapisz'}
            </button>
        </form>
    );
}

export default ClientFormComponent;

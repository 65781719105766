import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {
    Maybe, OfferFragment,
    OfferPaginationInfo, OffersQuery,
    useOffersLazyQuery
} from "../../../../graphql/$graphql";
import {Pagination} from "../../../../components/Pagination";
import {
    getUuidFromId,
    parseFilterParamsFromUrl,
    toDateFormat,
    toDateTimeFormat
} from "../../../../helpers/utils.helper";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faGear} from '@fortawesome/pro-regular-svg-icons'
import {NumberFormat, SortableColumn} from "../../../../components";
import {OfferFilterType} from "../../types/offer.types";
import Table from "react-bootstrap/Table";
import {OfferDropdownOptions, OfferFilter} from "../../components";
import {ProcessFilterType} from "../../../process/types/process.types";
import {ApolloQueryResult} from "@apollo/client";

interface OfferListProps {
}

const defaultOfferFilter: ProcessFilterType = {
    page: 1,
    search: ''
}

const OfferListView: React.FC<OfferListProps> = () => {
    const locationParams = useLocation().search;
    const [offerFilter, setOfferFilter] = useState<OfferFilterType>(parseFilterParamsFromUrl(locationParams));
    const [offersQuery, {loading}] = useOffersLazyQuery();
    const [offersList, setOffersList] = useState<OfferFragment[]>([]);
    const [paginationInfo, setPaginationInfo] = useState<Maybe<OfferPaginationInfo>>();

    const fetchOffers = () => {
        const filter: ProcessFilterType = {...defaultOfferFilter, ...offerFilter}
        offersQuery({variables: filter})
            .then((offersQuery: ApolloQueryResult<OffersQuery>) => {
                setOffersList(offersQuery.data.offers.collection);
                setPaginationInfo(offersQuery.data.offers.paginationInfo);
            });
    }

    useEffect(() => {
        const newOfferFilter = parseFilterParamsFromUrl(locationParams);
        if (JSON.stringify(newOfferFilter) !== JSON.stringify(offerFilter)) {
            setOfferFilter(newOfferFilter);
        }

    }, [locationParams])

    useEffect(() => {
        fetchOffers();
    }, [offerFilter]);


    return (
        <>
            <h1>Lista ofert</h1>
            <OfferFilter clientFilter={offerFilter}/>

            {loading && (<>Loading...</>)}

            {offersList.length > 0 && (
                <>
                    <Table hover bordered size="sm">
                        <thead>
                        <tr>
                            <th scope="col">
                                <SortableColumn label="Data dodania" orderKey="created" params={offerFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Dodał" orderKey="userCreated_name" params={offerFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Numer" orderKey="number" params={offerFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Tytuł" orderKey="title" params={offerFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Klient" orderKey="client_name" params={offerFilter}/>
                            </th>
                            <th scope="col" className="text-center">
                                <SortableColumn label="Status" orderKey="status_position" params={offerFilter}/>
                            </th>
                            <th scope="col" className="text-center">
                                <SortableColumn label="Termin" orderKey="termDate" params={offerFilter}/>
                            </th>
                            <th scope="col" className="text-end">
                                <SortableColumn label="Wartość bazowa" orderKey="priceRegular" params={offerFilter}/>
                            </th>
                            <th scope="col" className="text-end">
                                <SortableColumn label="Rabat" orderKey="priceDiscount" params={offerFilter}/>
                            </th>
                            <th scope="col" className="text-end">
                                <SortableColumn label="Rabat%" orderKey="percentDiscount" params={offerFilter}/>
                            </th>
                            <th scope="col" className="text-end">
                                <SortableColumn label="Wartość" orderKey="price" params={offerFilter}/>
                            </th>
                            <th scope="col" className="text-end">
                                <a href="#"><FontAwesomeIcon icon={faGear}/></a>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {offersList.map(offer => (
                            <tr key={offer.id}>
                                <td>{toDateTimeFormat(offer.created)}</td>
                                <td>{offer.userCreated.name + ' ' + offer.userCreated.surname}</td>
                                <td>
                                    <Link to={`/offers/` + getUuidFromId(offer.id)}>
                                        {offer.number}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/offers/` + getUuidFromId(offer.id)}>
                                        {offer.title}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={'/clients/' + getUuidFromId(offer.client.id)}>
                                        {offer.client.name}
                                    </Link>
                                </td>
                                <td className="text-center">
                                    {offer.status.name}
                                </td>
                                <td className="text-center">
                                    {offer.termDate && toDateFormat(offer.termDate)}
                                </td>
                                <td className="text-end">
                                    <NumberFormat value={offer.priceRegular}/>
                                </td>
                                <td className="text-end">
                                    <NumberFormat value={offer.priceDiscount}/>
                                </td>
                                <td className="text-end">
                                    <NumberFormat value={offer.percentDiscount} extension="%"/>
                                </td>
                                <td className="text-end">
                                    <NumberFormat value={offer.price}/>
                                </td>
                                <td>
                                    <OfferDropdownOptions offer={offer} fetchOffers={fetchOffers}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    {paginationInfo && <Pagination page={offerFilter.page ?? 1} {...paginationInfo} />}
                </>
            )}
        </>
    );
}

export default OfferListView;
import React from "react";
import {UserCard} from "../../components";
import {useParams} from "react-router-dom";
import {Maybe, UserFragment, useUserQuery} from "../../../../graphql/$graphql";
import {Col, Row} from "react-bootstrap";

interface UserViewProps {}

const UserView: React.FC<UserViewProps> = () => {
    const { id } = useParams();
    const {data} = useUserQuery({'variables': {'id': '/api/users/'+id}});
    const user:Maybe<UserFragment> = data?.user ?? null;

    return (
        <div className="view-user">
            <h1>Karta użytkownika</h1>
            <br/>

            <Row>
                <Col md={6} >
                    {user && <UserCard user={user} />}
                </Col>
            </Row>

        </div>
    )
}

export default UserView;
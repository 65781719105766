import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {authReducer} from "../modules/auth/store";
// import filterReducer from "./filterSlice";
// import cartReducer from "./cartSlice";
// import productsReducer from "./productsSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        // cart: cartReducer,
        // products: productsReducer,
    }
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

import React, {ReactElement} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp, faSortDown } from '@fortawesome/pro-duotone-svg-icons'
import { faSort } from '@fortawesome/pro-light-svg-icons'

interface SortableColumnProps{
    label: string,
    orderKey: string,
    params: any
}

const SortableColumnComponent:React.FC<SortableColumnProps> = ({label, orderKey, params}): ReactElement => {

    let link = '';
    let urlParams = {...params};
    let orderExists = false;
    delete urlParams.page;

    if (typeof params.order != "undefined") {
        orderExists = params.order.find(order => order?.[orderKey]);
    }

    const orderDirection = orderExists?.[orderKey] === 'ASC' ? 'DESC' : 'ASC';
    urlParams['order[0]['+orderKey+']'] = orderDirection;
    delete(urlParams.order);

    if (Object.values(urlParams).length > 0) {
        link += '?' + (new URLSearchParams(urlParams).toString())
    }

    return (
        <Link to={link} className={"text-nowrap "+(orderExists ? 'is-sorted' : '')}>
            {label}
            {orderExists
                ? <FontAwesomeIcon icon={'ASC' === orderDirection ? faSortDown: faSortUp} fixedWidth={true} />
                : <FontAwesomeIcon icon={faSort}  fixedWidth={true} />
            }
        </Link>
    );
}

 export default SortableColumnComponent;
import React from 'react';
import {Link, NavLink} from "react-router-dom";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../store";
import {logout} from "../../../auth/store/authSlice";

const NavBar = () => {
    const dispatch = useDispatch<AppDispatch>();

    const logoutHandler = (e) => {
        e.preventDefault();
        dispatch(logout());
    }

    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">OneSpace</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">

                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" aria-current="page" to={"/"}>Dashboard</NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <NavLink className="nav-link" to="/users">Użytkownicy</NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <NavLink className="nav-link" to="/clients">Klienci</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/offers">Oferty</NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <NavLink className="nav-link" to="/process">Serwis</NavLink>
                        </li>
                    </ul>

                    <div className="d-flex">
                        <Link to="/offers/create" type="button" className="btn btn-primary">+ Dodaj ofertę</Link>
                        &nbsp;
                        <Link to="/clients/create" type="button" className="btn btn-outline-primary">+ Dodaj klienta</Link>
                        &nbsp;
                        <button onClick={logoutHandler} type="button" className="btn btn-outline-primary">Wyloguj</button>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
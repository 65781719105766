// import 'dotenv/config';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './views/App';

import 'bootstrap';

import reportWebVitals from './reportWebVitals';
import {store} from "./store";
import {client as apolloClient} from "./library/apollo.library";
import {Provider} from "react-redux";
import {ApolloProvider} from "@apollo/client";

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.Fragment>
      <Provider store={store}>
          <ApolloProvider client={apolloClient}>
            <App />
          </ApolloProvider>
      </Provider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import {UserFragment} from "../../../../graphql/$graphql";
import {Link} from "react-router-dom";
import {getUuidFromId} from "../../../../helpers/utils.helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/pro-light-svg-icons";
import Table from "react-bootstrap/Table";

interface UserCardProps {
    user: UserFragment
}

const UserCardComponent:React.FC<UserCardProps> = ({user}) => {
    return (
        <Table bordered>
            <tbody>
            <tr>
                <td scope="col-1" className={"text-end"}></td>
                <td scope="col-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="m-0">{user.name + " " + user.surname}</h4>
                        <Link to={"/user/edit/" + getUuidFromId(user.id)}>
                            <FontAwesomeIcon icon={faPencil}/> edytuj
                        </Link>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="text-end">Telefon:</td>
                <td>{user.phone}</td>
            </tr>
            <tr>
                <td className="text-end">E-mail:</td>
                <td>{user.email}</td>
            </tr>
            <tr>
                <td className="text-end">Odział:</td>
                <td>{user?.department?.name ?? ''}</td>
            </tr>
            <tr>
                <td className="text-end">Stanowisko:</td>
                <td>{user.jobPosition}</td>
            </tr>
            <tr>
                <td className="text-end">Odział:</td>
                <td>
                    {user.department && <>{user.department.code}  {user.department.name}</>}
                </td>
            </tr>
            </tbody>
        </Table>
    );
}

export default UserCardComponent;
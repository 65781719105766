import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {findList} from "../../uitls/axios";
import {ShipmentMethod, ShipmentMethodListRequest, ShipmentMethodTypeEnum} from "../../types/shipmentMethod.types";

interface FormShipmentSelectProps {
    type: ShipmentMethodTypeEnum,
    name: string,
    value: string,
    onChange: React.ChangeEventHandler<HTMLSelectElement>,
    isInvalid: boolean
}

const FormShipmentSelectComponent: React.FC<FormShipmentSelectProps> = (
    {type, name, value, onChange, isInvalid = false}
) => {
    const [shipmentMethodList, setShipmentMethodList] = useState<ShipmentMethod[]>([])
    useEffect(() => {
        const request: ShipmentMethodListRequest = {
            type: type
        }
        findList(request).then((response: ShipmentMethod[]) => {
            setShipmentMethodList(response)
        });
    }, []);

    return (
        <Form.Select
            value={value}
            name={name}
            onChange={onChange}
            isInvalid={isInvalid}
        >
            <option value="">- wybierz metodę dostawy -</option>
            {shipmentMethodList.map(shipmentMethod => (
                <option value={shipmentMethod.uuid} key={shipmentMethod.uuid}>
                    {shipmentMethod.name}
                </option>
            ))}
        </Form.Select>
    );
}

export default FormShipmentSelectComponent;
import React from 'react';
import {Link} from "react-router-dom";

const App404 = () => {

    return (
        <>
            <h1>404 - Page not found</h1>

            <div>
                <Link to="/" className="btn btn-link">Return to home page</Link>
            </div>
        </>
    );

}

export default App404;
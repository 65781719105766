import React, {ReactElement} from "react";
import FilterComponent from "../../../../components/Filter/Filter.component";
import FilterSearch from "../../../../components/Filter/components/FilterSearch/FilterSearch.component";
import {ClientFilterType} from "../../types/client.types";
import FilterStatus from "../../../../components/Filter/components/FilterStatus/FilterStatus.component";
import {Col} from "react-bootstrap";

interface ClientFilterProps {
    clientFilter: ClientFilterType
}

const ClientFilterComponent:React.FC<ClientFilterProps> = ({clientFilter}): ReactElement => {
    let defaultFilter: ClientFilterType = {};
    if (typeof clientFilter.order !== "undefined") {
        defaultFilter.order = clientFilter.order;
    }

    return (
        <FilterComponent defaultParams={defaultFilter}>
            <FilterSearch value={clientFilter.search ?? ''} />
        </FilterComponent>
    );
}

export default ClientFilterComponent;
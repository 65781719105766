import {client as clientAxios} from "../../../../library/axios.library";
import {AxiosResponse} from "axios";

export const downloadOffer = async (offerId: string) => {
    return await clientAxios.get<never, AxiosResponse>('/api/v1/offers/' + offerId + '/download', {
        responseType: 'blob'
    });
}

export const cloneOffer = async (offerId: string) => {
    return await clientAxios.post<never, AxiosResponse>('/api/v1/offers/'+offerId+'/clone');
}

export const sendOfferEmail = async (offerId: string) => {
    return await clientAxios.post<never, AxiosResponse>('/api/v1/offers/'+offerId+'/send-email');
}

import React, {useEffect, useState} from "react";
import {
    DictionaryFragment,
    useDictionariesLazyQuery,
} from "../../../../graphql/$graphql";
import {getUuidFromId} from "../../../../helpers/utils.helper";
import {Form} from "react-bootstrap";
import {CommonErrorType} from "../../../../types/global.types";

interface FormDictionarySelectProps {
    model: string,
    changeHandler: Function,
    value: string,
    errors?: CommonErrorType[]
    name?: string,
}

const FormDictionarySelectComponent: React.FC<FormDictionarySelectProps> = (
    {model, changeHandler, value, errors = [], name = "department"}
) => {
    const [selected, setSelected] = useState<string>(value);
    const [query, {refetch}] = useDictionariesLazyQuery();
    const [dictionaryList, setDictionaryList] = useState<DictionaryFragment[]>([]);

    useEffect(() => {
        refetch({'model': model}).then(result => {
            setDictionaryList(result?.data?.dictionaries ?? []);
        })
    }, [])

    const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const dictionaryId: string = e.target.value;
        setSelected(dictionaryId);
        changeHandler(dictionaryId)
    }

    return (
        <>
            <Form.Select
                name="status"
                value={selected}
                onChange={onChangeHandler}
                isInvalid={errors.length > 0 ? true : false}
            >
                {dictionaryList.map(dictionary => (
                    <option value={getUuidFromId(dictionary.id)} key={dictionary.id}>
                        {dictionary.name}
                    </option>
                ))}
            </Form.Select>
            {errors.length > 0 && (
                <Form.Control.Feedback type="invalid">
                    {errors.map(({message}, key) => <div key={key}>{message}</div>)}
                </Form.Control.Feedback>
            )}
        </>
    );
}

export default FormDictionarySelectComponent;

import React, {useEffect, useState} from 'react';
import {client as clientAxios} from "../../../../library/axios.library";
import {OfferForm} from "../../components";
import {OfferFormType} from "../../types/offer.types";
import {useLocation} from "react-router-dom";
import {ClientFragment, useClientLazyQuery} from "../../../../graphql/$graphql";
import {getUuidFromId} from "../../../../helpers/utils.helper";
import moment from "moment/moment";

const OfferCreateView = () => {
    const searchParams = new URLSearchParams(useLocation().search);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [offerInit, setOfferInit] = useState<OfferFormType|null>(null);

    const [clientQuery, {loading, data, error}] = useClientLazyQuery();

    const initOfferForm = () => {
        clientAxios.get('/api/v1/offers/init')
            .then(response => {
                const offerInitData = response.data.data;
                setLoading(false);
                setOfferInit(prevState => {
                    const newState = {...prevState,
                        number: offerInitData.number,
                        status: offerInitData.status,
                        termDate: offerInitData.termDate ? moment(offerInitData.termDate).format('YYYY-MM-DD') : null,
                    }

                    return newState;
                });
            });
    }

    useEffect(() => {
        initOfferForm()
        if (searchParams.get('client')) {
            clientQuery({'variables': {'id': '/api/clients/' + searchParams.get('client')}})
                .then(result => result.data.client)
                .then((client: ClientFragment) => {
                    if (!client) return;

                    setOfferInit(prevState => {
                        return {...prevState, client: {id: getUuidFromId(client.id), name: client.name}}
                    });
                })
        }
    }, []);


    return (
        <>
            <h1>Nowa oferta</h1>

            <div>
                <h5 className="mb-3">Dane oferty</h5>
                <hr/>
                {isLoading && <>Loading...</>}
                {offerInit && <OfferForm offerForm={offerInit} />}
            </div>
        </>
    );

}

export default OfferCreateView;
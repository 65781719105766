import React, {useEffect, useState} from "react";
import Select from "react-select";
import {UserFragment, useUsersLazyQuery} from "../../../../graphql/$graphql";
import {getUuidFromId} from "../../../../helpers/utils.helper";
import {CommonErrorType} from "../../../../types/global.types";
import {Form} from "react-bootstrap";

interface FormUserSelectProps {
    changeHandler: Function,
    value: string,
    name?: string,
    errors?: CommonErrorType[]
}

const FormUserSelectComponent: React.FC<FormUserSelectProps> = (
    {changeHandler, value, name = "user", errors = []}
) => {

    const [selectedUser, setSelectedUser] = useState<any>('');
    const [usersQuery, {refetch: usersRefetch}] = useUsersLazyQuery();
    const [userList, setUserList] = useState<any[]>([]);

    useEffect(() => {
        usersRefetch().then(result => {
            const users = result?.data?.users?.collection ?? [];
            let selectOptions = [];
            users.map((user: UserFragment) => {
                selectOptions.push({value: getUuidFromId(user.id), label: `${user.surname} ${user.name}`});
            })
            setUserList(selectOptions);
            setSelectedUser(selectOptions.find(option => option.value == value))
        })
    }, [])

    const onChangeHandler = (user) => {
        setSelectedUser(user);
        changeHandler(selectedUser.value)
    }

    return (
        <>
            <Select
                options={userList}
                name={name ?? "user"}
                value={selectedUser}
                className={'p-0 form-select ' + (errors.length > 0 ?  ' is-invalid' : '')}
                onChange={onChangeHandler}
                placeholder="Wybierz użytkownika"
                noOptionsMessage={() => "Nie znaleziono użytkownika"}
                styles={{
                    control: (baseStyles) => ({...baseStyles, border: '0'})
                }}
            />
            {errors.length > 0 && (
                <Form.Control.Feedback type="invalid">
                    {errors.map(({message}, key) => <div key={key}>{message}</div>)}
                </Form.Control.Feedback>
            )}
        </>
    );
}

export default FormUserSelectComponent;

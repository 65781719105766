import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {getProcess} from "../../utils/axios";
import {Process} from "../../types/process.types";
import {Maybe} from "../../../../types/global.types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faSpinner, faUser, faScrewdriverWrench} from "@fortawesome/pro-light-svg-icons";
import {ProcessDropdownOptions} from "../../components";
import {Alert, Card, Col, Row, Stack} from "react-bootstrap";

interface ProcessCardProps {
}

const ProcessCardView: React.FC<ProcessCardProps> = () => {
    const {id: processId} = useParams();
    const [process, setProcess] = useState<Maybe<Process>>();
    const [loading, setLoading] = useState<boolean>(false);

    const fetchProcess = () => {
        setLoading(true);
        getProcess(processId).then((process: Process) => {
            setProcess(process);
        }).finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchProcess();
    }, []);

    return (
        <div className="view-process-card">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h1 className="mb-0">
                    Karta serwisu
                    {process && (
                        <>: <span className="fw-light">{process.title}</span></>
                    )}
                </h1>
                <div>
                    {process && <ProcessDropdownOptions process={process} buttonVariant="outline-primary"/>}
                </div>
            </div>

            {loading && (<div><FontAwesomeIcon icon={faSpinner} fixedWidth={true} spin={true}/> Loading...</div>)}

            {process && (
                <>
                    <Row className="mb-3">
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Stack direction="horizontal">
                                        <div>
                                            <FontAwesomeIcon icon={faScrewdriverWrench} fixedWidth={true}/>&nbsp;
                                            Szczegóły serwisu
                                        </div>
                                        <Link to={`/process/edit/${process.id}`} className="ms-auto">
                                            <FontAwesomeIcon icon={faPencil}/> edytuj
                                        </Link>
                                    </Stack>
                                </Card.Header>
                                <Card.Body>
                                    <dl className="dl-horizontal">
                                        <dt></dt>
                                        <dd><h5 className="m-0">{process.number}</h5></dd>
                                    </dl>
                                    <dl className="dl-horizontal">
                                        <dt>Tytuł:</dt>
                                        <dd>{process.title}</dd>
                                    </dl>
                                    {process.departmentAccount && (
                                        <dl className="dl-horizontal">
                                            <dt>Oddział:</dt>
                                            <dd>{process.departmentAccount.code} - {process.departmentAccount.name}</dd>
                                        </dl>
                                    )}
                                    <dl className="dl-horizontal">
                                        <dt>Data przyjęcia:</dt>
                                        <dd>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>{process.date}</div>
                                                <div className="fst-italic small text-muted">{process.createdAt}</div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl className="dl-horizontal">
                                        <dt>Przypisane do:</dt>
                                        <dd>{process.user.name} {process.user.surname}</dd>
                                    </dl>
                                    <dl className="dl-horizontal border-0">
                                        <dt>Status:</dt>
                                        <dd>{process.status.name}</dd>
                                    </dl>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Stack direction="horizontal">
                                        <div>
                                            <FontAwesomeIcon icon={faUser} fixedWidth={true}/> Klient
                                        </div>
                                    </Stack>
                                </Card.Header>
                                <Card.Body>
                                    <dl className="dl-horizontal">
                                        <dd>
                                            <Link to={process?.customer?.client ? `/clients/${process.customer.client.id}` : '#'}>
                                                <h5 className="m-0">
                                                    {process.customer.firstName} {process.customer.lastName}
                                                </h5>
                                            </Link>
                                        </dd>
                                    </dl>
                                    {process.customer.companyName && (
                                        <dl className="dl-horizontal">
                                            <dt>Firma:</dt>
                                            <dd>{process.customer.companyName}</dd>
                                        </dl>
                                    )}
                                    {process.customer.taxNumber && (
                                        <dl className="dl-horizontal">
                                            <dt>NIP:</dt>
                                            <dd>{process.customer.taxNumber}</dd>
                                        </dl>
                                    )}

                                    <dl className="dl-horizontal">
                                        <dt>E-mail:</dt>
                                        <dd>
                                            <a href={"mailto:" + process.customer.email}>
                                                {process.customer.email}
                                            </a>
                                        </dd>
                                    </dl>
                                    <dl className="dl-horizontal">
                                        <dt>Telefon:</dt>
                                        <dd>{process.customer.phone}</dd>
                                    </dl>
                                    <dl className="dl-horizontal border-0">
                                        <dt>Adres:</dt>
                                        <dd>
                                            <div>{process.customer.address.street}</div>
                                            <div>{process.customer.address.postCode} {process.customer.address.city}</div>
                                        </dd>
                                    </dl>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h5 className="border-bottom pb-2">Opis usterki</h5>
                            {!process.note ? (
                                <Alert className="alert-warning">Nie podano opisu usterki</Alert>
                            ) : (
                                <em style={{whiteSpace: "pre-wrap"}}>{process.note}</em>
                            )}
                        </Col>
                    </Row>
                </>
            )}
        </div>
    )
}

export default ProcessCardView;
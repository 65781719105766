import React, {ReactElement} from 'react';
import {ProcessFragment} from "../../../../graphql/$graphql";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical, faEye, faPen} from "@fortawesome/pro-regular-svg-icons";
import {Link} from "react-router-dom";
import {getUuidFromId} from "../../../../helpers/utils.helper";
import {Maybe} from "../../../../types/global.types";
import {Process} from "../../types/process.types";
import {ButtonVariant} from "react-bootstrap/types";

interface ProcessOptionsProps {
    process: ProcessFragment | Process
    buttonSize?: Maybe<'sm' | 'lg'>
    buttonVariant?: Maybe<ButtonVariant>
}

const ProcessDropdownOptionsComponent:React.FC<ProcessOptionsProps> = (
    {process, buttonSize, buttonVariant}
): ReactElement => {

    return (
        <Dropdown>
            <Dropdown.Toggle variant={buttonVariant} size={buttonSize}>
                <FontAwesomeIcon icon={faEllipsisVertical} fixedWidth={true}/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item as={Link} to={'/process/' + getUuidFromId(process.id)}>
                    <FontAwesomeIcon icon={faEye} fixedWidth /> Karta</Dropdown.Item>
                <Dropdown.Item as={Link} to={"/process/edit/"+getUuidFromId(process.id)}>
                    <FontAwesomeIcon icon={faPen} fixedWidth /> Edycja
                </Dropdown.Item>
                {/*<Dropdown.Divider />*/}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ProcessDropdownOptionsComponent;
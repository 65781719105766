import React, {ReactElement} from 'react';
import {ClientFragment} from "../../../../graphql/$graphql";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsis, faEye, faPen, faPlus} from "@fortawesome/pro-regular-svg-icons";
import {Link} from "react-router-dom";
import {getUuidFromId} from "../../../../helpers/utils.helper";

interface ClientOptionsProps {
    client: ClientFragment
}

const ClientDropdownOptionsComponent:React.FC<ClientOptionsProps> = ({client}): ReactElement => {

    return (
        <Dropdown>
            <Dropdown.Toggle variant="light" >
                <FontAwesomeIcon icon={faEllipsis}/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item as={Link} to={'/clients/' + getUuidFromId(client.id)}>
                    <FontAwesomeIcon icon={faEye} fixedWidth /> Karta</Dropdown.Item>
                <Dropdown.Item as={Link} to={"/clients/edit/"+getUuidFromId(client.id)}>
                    <FontAwesomeIcon icon={faPen} fixedWidth /> Edycja
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item as={Link} to={`/offers/create?client=`+ getUuidFromId(client.id)}>
                    <FontAwesomeIcon icon={faPlus} fixedWidth /> Dodaj ofertę
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ClientDropdownOptionsComponent;
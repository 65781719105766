import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import { API_URI } from "../config/common.config";
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";

const withHttp = createHttpLink({
  uri: API_URI+'/api/graphql',
});

const withAuth = setContext((_, { headers }) => {
  const token = window.localStorage.getItem('auth.token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const link = withAuth.concat(withHttp);

export const client = new ApolloClient({
  // link: authLink.concat(httpLink),
  link: link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all"
    }
  }
});

export default client;

import Swal, {SweetAlertOptions} from "sweetalert2";

export const cloneOfferSwal = (options?: SweetAlertOptions) => {
    return Swal.fire({
        title: 'Czy skopiować ofertę?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'TAK, kopiuj!',
        cancelButtonText: 'Anuluj',
        showLoaderOnConfirm: true,
        ...options
    });
}

export const sendOfferEmailSwal = (options?: SweetAlertOptions) => {
    return Swal.fire({
        title: 'Czy wysłać ofertę na e-mail klienta?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'TAK, wyślij!',
        cancelButtonText: 'Anuluj',
        showLoaderOnConfirm: true,
        ...options
    });
}
import axios, { AxiosError } from "axios";
import { API_URI } from "../config/common.config";


export const client = axios.create({
    baseURL: API_URI,
});

client.interceptors.request.use(
    request => {
      const token = window.localStorage.getItem('auth.token');
      if (token) {
        request.headers['Authorization'] = `Bearer ${token}`
      }
      return request;
    }, (error) => {
      return Promise.reject(error);
    }
);

export const getError = <T>(error: unknown) => error as AxiosError<T>;
